'use strict';

let vinFields = [
  { "Variable": "Destination Market", "VariableId": 10 },
  { "Variable": "Make", "VariableId": 26 },
  { "Variable": "Manufacturer Name", "VariableId": 27 },
  { "Variable": "Model", "VariableId": 28 },
  { "Variable": "Model Year", "VariableId": 29 },
  { "Variable": "Plant City", "VariableId": 31 },
  { "Variable": "Series", "VariableId": 34 },
  { "Variable": "Trim", "VariableId": 38 },
  { "Variable": "Vehicle Type", "VariableId": 39 },
  { "Variable": "Plant Country", "VariableId": 75 },
  { "Variable": "Plant Company Name", "VariableId": 76 },
  { "Variable": "Plant State", "VariableId": 77 },
  { "Variable": "Trim2", "VariableId": 109 },
  { "Variable": "Series2", "VariableId": 110 },
  { "Variable": "Note", "VariableId": 114 },
  { "Variable": "Base Price ($)", "VariableId": 136 },
  { "Variable": "Manufacturer Id", "VariableId": 157 },
  { "Variable": "Cash For Clunkers", "VariableId": 158 },
  { "Variable": "Body Class", "VariableId": 5 },
  { "Variable": "Doors", "VariableId": 14 },
  { "Variable": "Windows", "VariableId": 40 },
  { "Variable": "Wheel Base Type", "VariableId": 60 },
  { "Variable": "Track Width", "VariableId": 159 },
  { "Variable": "Gross Vehicle Weight Rating", "VariableId": 25 },
  { "Variable": "Bed Length (inches)", "VariableId": 49 },
  { "Variable": "Curb Weight (pounds)", "VariableId": 54 },
  { "Variable": "Wheel Base (inches)", "VariableId": 111 },
  { "Variable": "Wheel Base (inches) up to", "VariableId": 112 },
  { "Variable": "Bed Type", "VariableId": 3 },
  { "Variable": "Cab Type", "VariableId": 4 },
  { "Variable": "Trailer Type Connection", "VariableId": 116 },
  { "Variable": "Trailer Body Type", "VariableId": 117 },
  { "Variable": "Trailer Length (feet)", "VariableId": 118 },
  { "Variable": "Other Trailer Info", "VariableId": 155 },
  { "Variable": "Number of Wheels", "VariableId": 115 },
  { "Variable": "Wheel Size Front (inches)", "VariableId": 119 },
  { "Variable": "Wheel Size Rear (inches)", "VariableId": 120 },
  { "Variable": "Entertainment System", "VariableId": 23 },
  { "Variable": "Steering Location", "VariableId": 36 },
  { "Variable": "Number of Seats", "VariableId": 33 },
  { "Variable": "Number of Seat Rows", "VariableId": 61 },
  { "Variable": "Transmission Style", "VariableId": 37 },
  { "Variable": "Transmission Speeds", "VariableId": 63 },
  { "Variable": "Drive Type", "VariableId": 15 },
  { "Variable": "Axles", "VariableId": 41 },
  { "Variable": "Axle Configuration", "VariableId": 145 },
  { "Variable": "Brake System Type", "VariableId": 42 },
  { "Variable": "Brake System Description", "VariableId": 52 },
  { "Variable": "Battery Info", "VariableId": 1 },
  { "Variable": "Battery Type", "VariableId": 2 },
  { "Variable": "Number of Battery Cells per Module", "VariableId": 48 },
  { "Variable": "Battery Current (Amps)", "VariableId": 57 },
  { "Variable": "Battery Voltage (Volts)", "VariableId": 58 },
  { "Variable": "Battery Energy (KWh)", "VariableId": 59 },
  { "Variable": "EV Drive Unit", "VariableId": 72 },
  { "Variable": "Battery Current (Amps) up to", "VariableId": 132 },
  { "Variable": "Battery Voltage (Volts) up to", "VariableId": 133 },
  { "Variable": "Battery Energy (KWh) up to", "VariableId": 134 },
  { "Variable": "Number of Battery Modules per Pack", "VariableId": 137 },
  { "Variable": "Number of Battery Packs per Vehicle", "VariableId": 138 },
  { "Variable": "Charger Level", "VariableId": 127 },
  { "Variable": "Charger Power (KW)", "VariableId": 128 },
  { "Variable": "Engine Number of Cylinders", "VariableId": 9 },
  { "Variable": "Displacement (CC)", "VariableId": 11 },
  { "Variable": "Displacement (CI)", "VariableId": 12 },
  { "Variable": "Displacement (L)", "VariableId": 13 },
  { "Variable": "Engine Stroke Cycles", "VariableId": 17 },
  { "Variable": "Engine Model", "VariableId": 18 },
  { "Variable": "Engine Power (KW)", "VariableId": 21 },
  { "Variable": "Fuel Type - Primary", "VariableId": 24 },
  { "Variable": "Valve Train Design", "VariableId": 62 },
  { "Variable": "Engine Configuration", "VariableId": 64 },
  { "Variable": "Fuel Type - Secondary", "VariableId": 66 },
  { "Variable": "Fuel Delivery / Fuel Injection Type", "VariableId": 67 },
  { "Variable": "Engine Brake (hp)", "VariableId": 71 },
  { "Variable": "Cooling Type", "VariableId": 122 },
  { "Variable": "Engine Brake (hp) up to", "VariableId": 125 },
  { "Variable": "Electrification Level", "VariableId": 126 },
  { "Variable": "Other Engine Info", "VariableId": 129 },
  { "Variable": "Turbo", "VariableId": 135 },
  { "Variable": "Top Speed (MPH)", "VariableId": 139 },
  { "Variable": "Engine Manufacturer", "VariableId": 146 },
  { "Variable": "Pretensioner", "VariableId": 78 },
  { "Variable": "Seat Belts Type", "VariableId": 79 },
  { "Variable": "Other Restraint System Info", "VariableId": 121 },
  { "Variable": "Curtain Air Bag Locations", "VariableId": 55 },
  { "Variable": "Seat Cushion Air Bag Locations", "VariableId": 56 },
  { "Variable": "Front Air Bag Locations", "VariableId": 65 },
  { "Variable": "Knee Air Bag Locations", "VariableId": 69 },
  { "Variable": "Side Air Bag Locations", "VariableId": 107 },
  { "Variable": "Driver Assist", "VariableId": 16 },
  { "Variable": "Adaptive Cruise Control", "VariableId": 81 },
  { "Variable": "Adaptive Headlights", "VariableId": 82 },
  { "Variable": "Anti-lock Braking System (ABS)", "VariableId": 86 },
  { "Variable": "Auto Brake / Auto Emergency Braking", "VariableId": 87 },
  { "Variable": "Blind Spot Monitoring / Detection", "VariableId": 88 },
  { "Variable": "Electronic Stability Control (ESC)", "VariableId": 99 },
  { "Variable": "Traction Control", "VariableId": 100 },
  { "Variable": "Forward Collision Warning", "VariableId": 101 },
  { "Variable": "Lane Departure Warning", "VariableId": 102 },
  { "Variable": "Lane Keep System", "VariableId": 103 },
  { "Variable": "Rear Visibility Camera", "VariableId": 104 },
  { "Variable": "Park Assist", "VariableId": 105 },
  { "Variable": "TPMS", "VariableId": 168 },
  { "Variable": "Bus Length (feet)", "VariableId": 147 },
  { "Variable": "Bus Floor Configuration Type", "VariableId": 148 },
  { "Variable": "Bus Type", "VariableId": 149 },
  { "Variable": "Other Bus Info", "VariableId": 150 },
  { "Variable": "Custom Motorcycle Type", "VariableId": 151 },
  { "Variable": "Motorcycle Suspension Type", "VariableId": 152 },
  { "Variable": "Motorcycle Chassis Type", "VariableId": 153 },
  { "Variable": "Other Motorcycle Info", "VariableId": 154 }
];

class VinExplosion {

  getFields() {
    if (!this.sortedFields) {
      this.sortedFields = vinFields.sort((a, b) => {
        return (a.Variable > b.Variable) ? 1 : ((b.Variable > a.Variable) ? -1 : 0);
      });
    }
    return this.sortedFields;
  }

  getField(name) {
    return this.getFields().find(fld => fld.Variable === name);
  }

  getFieldById(id) {
    return this.getFields().find(fld => fld.VariableId === id);
  }
}

module.exports = new VinExplosion();
