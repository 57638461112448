'use strict';

/**
 * @typedef {object} UrlOptions
 * @prop {string | boolean} [fallback]
 * @prop {string | boolean} [buster]
 * @prop {string} [scheme]
 */

let Decimal = require('./decimal'),
    Vin = require('./vin'),
    _ = require('lodash'),
    PhoneUtilities = require('./phone'),
    truncateHtml = require('truncate-html'),
    urlParser = require('url-parse'),
    PhoneUtil = require('./phone'),
    s3url = require('./s3Urls'),
    queryString = require('querystring');

class GeneratedTitle {
  constructor(title, usedDescription = false) {
    this.title = title;
    this.used_description = usedDescription;
  }

  toString() { return this.title; }
  toJSON() { return this.title; }
  toKnexParam() { return this.title; }
  valueOf() { return this.title; }
  [Symbol.toPrimitive](hint) {
    if (hint === 'number') return Number.NaN;
    return this.title;
  }
  get [Symbol.toStringTag]() { return 'GeneratedTitle'; }
}

class Utilities {

  /**
   * 
   * @param {any} image 
   * @param {null | 'large' | 'large_no' | 'medium' | 'small' | 'thumb' | 'noimage' | 'original'} size
   * @param {UrlOptions} options 
   */
  getS3Url(image, size, options) {
    return s3url.getS3Url(image, size, options);
  }

  hasOwnProperty(obj, property) {
    return Object.prototype.hasOwnProperty.call(obj, property);
  }
  
  ucFirst(str) {
    if (!str || typeof str !== 'string') return str;
    return str.charAt(0).toLocaleUpperCase() + str.slice(1);
  }

  allUppercase(req, res, next) {
    req.body = Utilities.setAllUppercase(req.body);
    next();
  }

  static setAllUppercase(obj) {
    if (Array.isArray(obj)) {
      return obj.map(o => Utilities.setAllUppercase(o));
    }
    else if (typeof obj === 'object') {
      let keys = Object.keys(obj);
      keys.forEach(key => {
        obj[key] = Utilities.setAllUppercase(obj[key]);
      });
      return obj;
    }
    else if (typeof obj === 'string')
      return obj.toLocaleUpperCase();
    else if (obj !== null && typeof obj !== 'undefined')
      return obj.toString().toLocaleUpperCase();
  }

  getYoutubeID(url) {
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
    var match = url.match(regExp);
    return (match && match[7].length === 11) ? match[7] : false;
  }
  
  generateItemTitle(item, titleFields) {
    let titleStr = [];

    if (item.dynamic_values && titleFields) {
      for (let field of titleFields) {
        let value = _.get(item, field.accessor);
        if (field.type_name === 'Static Text')
          value = field.static;
        if (value) {
          let part = [];
          if (['Textbox', 'Vehicle Info'].includes(field.type_name)) {
            part.unshift(value.replace(/\s\s+/g, ' '));
          }
          else if (['Textarea', 'Static Text'].includes(field.type_name)) {
            // trim value of new lines
            part.unshift(value.replace(/\r?\n|\r/g, ' ').replace(/\s\s+/g, ' '));
          }
          else if (field.type_name === 'Checkbox') {
            if (!field.choices || !field.choices.length) {
              // no choices simple select
              if (value === true || (value === 1) || (typeof value === 'string' && (value.toLowerCase() == 'true' || value == '1'))) {
                part.unshift(field.name.toLocaleUpperCase());
              }
            } else {
              if (!Array.isArray(value)) value = [value];
              value = value.map(v => field.choices.find(c => c.value == v))
                .filter(v => v) // filter nulls
                .map(v => v.name);
              part.unshift(value.join(', '));
            }
          }
          else if (['Radio', 'Dropdown'].includes(field.type_name)) {
            // get display of selected
            let found = field.choices.find(c => c.value == value);
            if (found) {
              part.unshift(found.name);
            }
          }

          if (field.label) {
            part.unshift(field.label.trim());
          }
          titleStr.push(part.join(' '));
        }
      }
    }

    let usedDescription = false;
    if (!titleStr.length) {
      if (item.description) {
        titleStr.push(item.description.split(/\s+/).slice(0, 10).join(' '));
        usedDescription = true;
      }
    }
    let title = titleStr.join(' ').trim().toLocaleUpperCase();
    return new GeneratedTitle(title, usedDescription);
  }

  getYearMakeModelVin(item, vehicleFields) {
    if (!item.dynamic_values) return {};

    // {variableId: {field_id: id on dyn_values, id: element id}}
    let yearId = Vin.getField('Model Year').VariableId;
    let makeId = Vin.getField('Make').VariableId;
    let manfId = Vin.getField('Manufacturer Name').VariableId;
    let modelId = Vin.getField('Model').VariableId;

    let vinField = vehicleFields.vin;
    let yearField = vehicleFields[yearId];
    let makeField = vehicleFields[makeId];
    let mfgField = vehicleFields[manfId];
    let modelField = vehicleFields[modelId];

    let ret = {};
    if (yearField) {
      let value = _.get(item, 'dynamic_values.' + yearField.field_id, false);
      ret.year = {
        field: yearField,
        value
      };
    }

    if (makeField || mfgField) {
      let value = false;
      if (makeField) {
        value = _.get(item, 'dynamic_values.' + makeField.field_id, false);
        ret.make = {
          field: makeField,
          value
        };
      }
      if (!value && mfgField) {
        value = _.get(item, 'dynamic_values.' + mfgField.field_id, false);
        if (value || !ret.make) {
          ret.make = {
            field: mfgField,
            value
          };
        }
      }
    }

    if (modelField) {
      let value = _.get(item, 'dynamic_values.' + modelField.field_id, false);
      ret.model = {
        field: modelField,
        value
      };
    }

    if (vinField) {
      let value = _.get(item, 'dynamic_values.' + vinField.field_id, false);
      ret.vin = {
        field: vinField,
        value
      };
    }

    ret.all_fields = [
      vinField,
      yearField,
      makeField,
      mfgField,
      modelField
    ].filter(v => v);
    
    return ret;
  }

  /**
   * Escapes special characters in a string to be safe for
   * attribute selector values.
   * 
   * @param {string} attr Attribute value to make safe
   */
  safeAttr(attr) {
    if (!attr) return attr;
    return attr.replace(/([ #;&,.+*~\':"!^$[\]()=>|\/@])/g, '\\$1');
  }

  // plainText(htmlContent) {
  //   if (!htmlContent) return '';
  //   let text = this.sanitizeHtml(htmlContent);
  //   text = entities.decode(text);
  //   return text;
  // }
  
  // sanitizeHtml(content) {
  //   let sanitized = sanitizeHtml(content, {
  //     allowedTags: [],
  //     allowedAttributes: []
  //   });
  //   return sanitized;
  // }

  // truncate(content, length, byWords) {
  //   if (!length) length = content.length;
  //   if (typeof byWords !== typeof false) byWords = false;

  //   let truncated = truncateHtml(content, length, {
  //     stripTags: true,
  //     byWords
  //   });
  //   return truncated;
  // }
  
  truncatePreserve(content, length, byWords) {
    if (typeof length !== 'number') length = content.length;
    if (typeof byWords !== typeof false) byWords = false;

    return truncateHtml(content, length, {
      excludes: 'img',
      byWords
    });
  }

  pugToJS(obj, spaces) {
    if (typeof obj === typeof undefined) return 'undefined';
    return JSON.stringify(obj, null, spaces).replace(/<\//g, '<\\/');
  }

  // Server-side normally uses jmaLib - exception made for extended functionality
  formatPhone(number, ext, forceInternational = false) {
    let ret = '';
    if (number) {
      ret += PhoneUtilities.format(number, forceInternational);

      if (ext) {
        ret += ' ext. ' + ext;
      }
    }
    return ret;
  }

  // Server-side actually uses jmaLib, this is for browser
  formatCurrency(amount, dollarSign) {
    if (dollarSign === true || typeof dollarSign === 'undefined') dollarSign = '$';
    if (amount) {
      amount = (new Decimal(amount)).toCurrency().toFixed(2).toString();
      return (dollarSign ? dollarSign : '') + amount.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
    return (dollarSign ? dollarSign : '') + '0.00';
  }

  // Server-side actually uses jmaLib, this is for browser
  formatPercent(value, decimals, sign) {
    if (!decimals && decimals !== 0) decimals = 2;
    if (!sign && sign !== false) sign = true;

    if (value) {
      value = (value * 100).toFixed(decimals).toString();
      return value.replace(/(\d)(?=(\d{3})+\.)/g, '$1,') + (sign ? '%' : '');
    }

    return "0.".padEnd(decimals + 2, '0') + (sign ? '%' : '');
  }

  // Server-side actually uses jmaLib, this is for browser
  formatNumber(num) {
    if (typeof num === 'string') num = parseFloat(num);
    if (num) {
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return num;
  }

  // Server-side actually uses jmaLib, this is for browser
  formatPhone(number, ext) {
    let ret = '';
    if (number) {
      ret += PhoneUtil.format(number);
    }
    if (number && ext) {
      ret += ' ext. ' + ext;
    }
    return ret;
  }

  formatAsClassName(value) {
    return value.replace(/[^a-zA-Z0-9]/g, function(s) {
      var c = s.charCodeAt(0);
      if (c == 32) return '-';
      return '_' + ('000' + c.toString(16)).slice(-4);
    });
  }

  objMissingAny(body, fields) {
    if (!Array.isArray(fields)) fields = [fields];
    let missing = [];
    let compares = [];

    fields.forEach(fieldset => {
      let any = false;
      for (let key in fieldset) {
        if (!fieldset.hasOwnProperty(key))
          continue;
        let notOK = !Object.hasOwnProperty.call(body, key) || !body[key] || !body[key].trim();
        if (notOK) {
          any = true;
          missing.push({key, name: fieldset[key]});
        }
      }
      compares.push(any);
    });

    compares = compares.filter(o => o);
    return compares.length === fields.length ? missing : false;
  }

  // Client Side Only
  getQuery(qs) {
    var query = {};
    if (!qs) {
      if (!window) return {};
      if (!window.location.search) return {};
      qs = (window.location.search[0] === '?' ? window.location.search.substr(1) : window.location.search);
    } else {
      try {
        let url = new urlParser(qs);
        if (url.query) {
          qs = (url.query[0] === '?' ? url.query.substr(1) : url.query);
        }
      }
      catch(e) {}
    }
    
    // for(var i = 0; i < qs.length; i++) {
    //   var pv = qs[i].split('=');
    //   query[decodeURIComponent(pv[0])] = decodeURIComponent(pv[1] || '');
    // }
    return queryString.parse(qs);
    // return query;
  }

  // Server-side actually uses jmaLib, this is for browser
  buildQueryString(query, prefix) {
    if (!query) query = {};

    let parts = [];
    for (let key in query) {
      if (!query.hasOwnProperty(key)) continue;

      let value = query[key];
      let name = prefix ? prefix + '[' + key + ']' : key;

      parts.push(
        (value !== null && typeof value === 'object') ?
        this.buildQueryString(value, name) :
        encodeURIComponent(name) + '=' + encodeURIComponent(value)
      );
    }

    return parts.length ? (prefix ? '' : '?') + parts.join('&') : '';
  }

  buildHref(path, query, hash, host) {
    // let parts = [];
    // for (let key in query) {
    //   if (!query.hasOwnProperty(key)) continue;
    //   parts.push(encodeURIComponent(key) + '=' + encodeURIComponent(query[key]));
    // }

    // let qs = parts.length ? '?' + parts.join('&') : '';
    let qs = this.buildQueryString(query);
    path = path ? path : window.location.pathname;
    hash = hash ? hash : window.location.hash;
    if (path && path[0] !== '/') path = '/' + path;
    if (host) {
      return host + path + qs + (hash ? '#' : '') + hash;
    }
    return window.location.protocol + '//' +
      window.location.hostname + 
      path + qs + (hash ? '#' : '') + hash;
  }

  mapDynamicFieldValues(dynamicFields, item) {
    let fields = _.cloneDeep(dynamicFields);
    for (let field of fields) {
      if (field.type_name === 'Static Text') {
        field.input_value = field.content;
      } else {
        field.input_value = _.get(item, 'dynamic_values.' + field.field_id);
        if (typeof field.input_value === 'undefined')
          field.input_value = '';
      }
    }
    return fields;
  }

  printValueWithFieldLabel(field, value) {
    if (field.is_print_label) {
      if (field.print_label_after_value) {
        return value + ' ' + field.name.trim();
      } else {
        return field.name.trim() + ' ' + value;
      }
    }
    return value;
  }

  /**
   * 
   * @param {Array} dynamic_values Array containing the dynamic field definitions with their values
   * @param {boolean} after Boolean indicating getting the fields AFTER the description
   */
  getFieldDescriptions(dynamic_values, after) {
    let descriptions = [];
    _.each(dynamic_values, field => {
      if (field.type_name !== 'Vehicle Info' && !field.is_print) return;
      if (typeof field.gen_title_order !== 'undefined') return;
      switch (field.type_name) {
        case 'Textbox':
        case 'Radio':
          if (field.input_value && field.is_after_desc === after)
            descriptions.push(this.printValueWithFieldLabel(field, field.input_value));
          break;
        case 'Checkbox':
          if ((!field.options.choices || !field.options.choices.length) && field.is_after_desc === after)
            descriptions.push(this.printValueWithFieldLabel(field, (field.input_value ? 'Yes' : 'No')));
          else {
            if (field.input_value.length && field.is_after_desc === after)
              descriptions.push(this.printValueWithFieldLabel(field, field.input_value.join(', ')));
          }
          break;
        case 'Dropdown':
          if (field.input_value && field.is_after_desc === after) {
            let option = _.find(field.options.choices, val => field.input_value === val.value);
            descriptions.push(this.printValueWithFieldLabel(field, (option ? option.name : field.input_value)));
          }
          break;
        case 'Static Text':
        case 'Textarea':
          if (field.input_value && field.is_after_desc === after) {
            descriptions.push(this.printValueWithFieldLabel(field, field.input_value));
          }
          break;
        case 'Vehicle Info':
          if (field.input_value && field.is_print && field.is_after_desc === after)
            descriptions.push(this.printValueWithFieldLabel(field, field.input_value));
          break;
      }
    });
    return descriptions;
  }

  generateDescription(item, dynamic_fields, plainTextFn) {
    // build the description based on the dynamic_values
    // skip Vehicle Information (Year/Make/Model)
    let dynamic_values = this.mapDynamicFieldValues(dynamic_fields, item);

    let descStr = plainTextFn(item.description);

    let fieldsBefore = this.getFieldDescriptions(dynamic_values, false);
    let fieldsAfter = this.getFieldDescriptions(dynamic_values, true);

    let descriptions = _.concat([], fieldsBefore, descStr, fieldsAfter);
    descriptions = _.compact(descriptions);
    
    let description = descriptions.join(', ');

    return description;
  }

  // groupLots(lots) {
  //   let groupedLots = [];

  //   lots.forEach(lot => {
  //     // ignore specifically grouped lots
  //     if (lot.bid_group_with) return;

  //     let newLot = _.cloneDeep(lot);

  //     if (lot.component_id === null) {
  //       newLot.quantity = 1;
  //       newLot.related = [];
  //       groupedLots.push(newLot);
  //       return;
  //     }

  //     let parent = null;
  //     if (newLot.simulcast_status < 2 || newLot.is_no_sale) {
  //       parent = groupedLots.find(l => {
  //         return l.inventory_id === newLot.inventory_id && 
  //           l.simulcast_status === newLot.simulcast_status &&
  //           (l.winning_bidder_id === newLot.winning_bidder_id || l.winning_live_registration_id === newLot.winning_live_registration_id) &&
  //           l.winning_bid_amount === newLot.winning_bid_amount;
  //       });
  //     }
  
  //     if (!parent) {
  //       newLot.quantity = 1;
  //       newLot.related = [];
  //       groupedLots.push(newLot);
  //       return;
  //     }
  //     parent.quantity++;
  //     parent.related.push(newLot);
  //   });

  //   lots.forEach(lot => {
  //     // only handle completed specifically grouped lots
  //     if (!lot.bid_group_with) return;
  
  //     let newLot = _.cloneDeep(lot);
  //     newLot.quantity = 1;
  //     newLot.related = [];
  
  //     let parent = groupedLots.find(l => l.id === newLot.bid_group_with);
  
  //     let addedToParent = false;
  //     if (parent.inventory_id === lot.inventory_id) {
  //       parent.quantity++;
  //     } else {
  //       // this is to add Quantity items as related to an different "lot number"
  //       let p = parent.related.find(l => l.id === lot.inventory_id);
  //       if (p) {
  //         p.quantity++;
  //         p.related.push(newLot);
  //         addedToParent = true;
  //       }
  //     }
      
  //     if (!addedToParent)
  //       parent.related.push(newLot);
  //   });

  //   return groupedLots;
  // }
}

module.exports = new Utilities();