/* global $ */
'use strict';

let inputTextTypes = ['text', 'url', 'email', 'search', 'tel'];
$.extend($.expr[':'], {
  alltext: function(elem) {
    return $.inArray(elem.type, inputTextTypes) !== -1;
  }
});

function inputUpperCase(e) {
  var ss = e.target.selectionStart;
  var se = e.target.selectionEnd;
  e.target.value = e.target.value.toLocaleUpperCase();
  // e.target.selectionStart = ss;
  // e.target.selectionEnd = se;
  e.target.setSelectionRange(ss, se);
}

$.fn.applyUpperCase = function(action) {
  this.off('input', inputUpperCase);
  if (action === 'destroy') return this;
  return this.each(function() {
    var $this = $(this);
    if (!$this.hasClass('normalcase')) {
      $this.priorityOn('input', inputUpperCase); //.trigger('input');
      $this.val(($this.val() || '').toLocaleUpperCase());
    }
  });
}