'use strict';

let CreditCards = require('creditcards'),
    types = CreditCards.card.types;

let cardImages = {
  'Visa': { icon: 'fab fa-cc-visa', image: '/img/cards/visa.png' },
  'Mastercard': { icon: 'fab fa-cc-mastercard', image: '/img/cards/mastercard.png' },
  'American Express': { icon: 'fab fa-cc-amex', image: '/img/cards/amex.png' },
  'Discover': { icon: 'fab fa-cc-discover', image: '/img/cards/discover.png' },
  'Maestro': { icon: 'fab fa-cc-discover', image: '/img/cards/discover.png' }, // some discover cards show as maestro
  'Diners Club': { icon: 'fab fa-cc-diners-club', image: '/img/cards/diners.png' },
  'JCB': { icon: 'fab fa-cc-jcb', image: '/img/cards/jcb.png' },
};

for (let name in cardImages) {
  if (cardImages.hasOwnProperty(name)) {
    let image = cardImages[name];
    let type = types.find(t => t.name === name);
    if (type) {
      type.icon = image.icon;
      type.image = image.image;
    }
  }
}

let onlyTypes = types.filter(t => {
  return cardImages.hasOwnProperty(t.name);
});

onlyTypes.get = function get(name) {
  let lowerName = name.toLowerCase();
  return onlyTypes.find(t => t.name.toLowerCase() === lowerName);
};

module.exports = CreditCards.withTypes(onlyTypes);