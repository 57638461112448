/* global $ */
'use strict';

$(function() {
  $(document).on('show.bs.collapse', '.card-collapse .collapse', function() {
    let $button = $($(this).attr('aria-labelledby')).next('.btn');
    $button.toggleClass('fa-caret-circle-down fa-caret-circle-up');
  })
  .on('hide.bs.collapse', '.card-collapse .collapse', function() {
    let $button = $($(this).attr('aria-labelledby')).next('.btn');
    $button.toggleClass('fa-caret-circle-down fa-caret-circle-up');
  });
});