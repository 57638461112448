/* global $, _ */
'use strict';

$(function() {

  $(document).on('change', 'select[data-statefilter]', function() {
    let $cboCountry = $(this);
    let stateFilter = $cboCountry.data('statefilter');
    let $boxState = $('#' + stateFilter);
    if (!$boxState.length)
      $boxState = $('select[name=' + stateFilter + '], input[name=' + stateFilter + ']');

    let $lblState = $('label[for=' + $boxState.attr('id') + ']');

    let selectedData = {};
    if ($boxState.prop('tagName') !== 'SELECT') {
      selectedData.id = $boxState.data('sel-id');
      selectedData.text = $boxState.val();
    } else {
      selectedData.id = $boxState.val();
      selectedData.text = $boxState.find(':selected').text();
    }
    
    let countryCode = $(this).val();
    let countryStates = [];
    if (countryCode) {
      let country = _.find($cboCountry.data('countries'), c => c.code === countryCode);
      if (country) {
        countryStates = _.filter($cboCountry.data('states'), s => s.country_id === country.id);
        $lblState.text(country.state_label);
      }
    }

    let $newBox = null, attr = null;
    if (countryStates.length) {
      // convert to select box if necessary else empty it
      if ($boxState.prop('tagname') !== 'SELECT') {
        $newBox = $('<select />');
        for (var i = 0; i < $boxState[0].attributes.length; i++) {
          attr = $boxState[0].attributes[i];
          $newBox.attr(attr.name, attr.value);
        }
        $newBox.addClass('select2');
        $boxState.replaceWith($newBox);
        $boxState = $newBox;
        $boxState.enableSelect2();
      } else {
        $boxState.empty();
      }

      // load the options
      $('<option />').appendTo($boxState);
      let hasSelectedState = false;
      _.each(countryStates, state => {
        if (state.id == selectedData.id)
          hasSelectedState = true;
        
        $('<option />', {value: state.id}).text(state.name)
          .appendTo($boxState);
      });
      if (hasSelectedState) $boxState.val(selectedData.id);
    } else {
      // if select - convert to input
      if ($boxState.prop('tagName') === 'SELECT') {
        $boxState.destroySelect2();
        $newBox = $('<input />');
        for (var j = 0; j < $boxState[0].attributes.length; j++) {
          attr = $boxState[0].attributes[j];
          $newBox.attr(attr.name, attr.value);
        }
        $newBox.removeClass('select2');
        $boxState.replaceWith($newBox);
        $boxState = $newBox;
        $boxState.val(selectedData.text ? selectedData.text : '');
        $boxState.data('sel-id', selectedData.id);
        $boxState.on('change', function() { $(this).data('sel-id', null); });
      }
    }
  });

  $(document).on('change', 'select[data-zipfilter]', function() {
    let countryCode = $(this).val();
    if (countryCode) {
      let country = _.find($(this).data('countries'), c => c.code === countryCode);
      if (country) {
        let $input = $('#' + $(this).data('zipfilter'));
        if (!$input.length) $input = $('input[name=' + $(this).data('zipfilter') + ']')
        let $label = $('label[for=' + $input.attr('id') + ']');
        $label.text(country.zip_label);
      }
    }
  });

});