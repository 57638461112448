
/* global $ */
'use strict';

$.isMobile = function isMobile() {
  return $('.site-burger-menu').css('display') !== 'none';
};

$.safeAttr = function(attr) {
  if (!attr) return attr;
  return attr.replace(/([ #;&,.+*~\':"!^$[\]()=>|\/@])/g, '\\$1');
};

$.fn.hasAttr = function hasAttr(attr) { return this[0].hasAttribute(attr); };
$.fn.deleteRow = function deleteRow() {
  let $self = this;
  return new Promise(resolve => {
    // specify the height to the current calculated height
    let currentHeight = $self.css('height');
    $self.css('height', currentHeight);

    // set overflow-y to hidden
    $self.css('overflow-y', 'hidden');

    // animate height, padding-bottom, padding-top to 0
    $self.animate({height: 0, 'padding-top': 0, 'padding-bottom': 0}, {
      duration: 500,
      complete: function() {
        // remove element
        $self.detach();
        resolve();
      }
    });
  });
};

$.fn.isInViewport = function isInViewport() {
  let elementTop = $(this).offset().top;
  let elementBottom = elementTop + $(this).outerHeight();

  let viewportTop = $(window).scrollTop();
  let viewportBottom = viewportTop + $(window).height();

  return elementBottom > viewportTop && elementTop < viewportBottom;
};

$.fn.paginateScroll = function paginateScroll(buffer = 50) {
  if (!this.isInViewport()) {
    var newTop = this.offset().top;
    if (newTop > buffer) newTop -= buffer;
    $('html, body').animate({ scrollTop: newTop }, 800);
  }
}