/* global $ */
'use strict';

$(function() {
  $(document).on('click', '.read-more .excerpt', function(e) {
    e.preventDefault();
    
    let $excerpt = $(this);
    let $full = $excerpt.siblings('.full');
    let srcHeight = $excerpt.height();
    
    $full.height(srcHeight);
    $full.parent().addClass('expanded');
    let targetHeight = $full.get(0).scrollHeight;
    $full.animate({ height: targetHeight }, 500, function() {
      $full.height('auto');
    });
  });
});