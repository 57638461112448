// @ts-check
'use strict';

/**
 * @typedef {object} UrlOptions
 * @prop {string | boolean} [fallback]
 * @prop {string | boolean} [buster]
 * @prop {string} [scheme]
 */

module.exports = {
  /**
   * 
   * @param {any} image 
   * @param {null | 'large' | 'large_no' | 'medium' | 'small' | 'thumb' | 'noimage' | 'original'} size
   * @param {UrlOptions} options 
   */
  getS3Url: function(image, size, options = {}) {
    /** @type {string | undefined} */
    let fallbackImage = undefined;
    if (options.fallback) {
      if (typeof options.fallback === 'boolean') {
        fallbackImage = '/images/no_image.png';
      }
      else if (typeof options.fallback === 'string') {
        fallbackImage = options.fallback;
      }
    }

    if (!image) {
      return fallbackImage;
    }

    let buster = '';
    if (options.buster) {
      if (typeof options.buster === 'boolean') {
        buster = '?_=' + new Date().getTime();
      }
      else if (typeof options.buster === 'string') {
        buster = options.buster;
      }
    }

    let scheme = options.scheme ? options.scheme + ':' : '';

    if (size == null) {
      return scheme + '//' + image.bucket + '.s3.amazonaws.com/' + image.link_url + image.file_name + buster;  
    }

    if (image.is_pending) {
      let urlPrefix = '';
      if (typeof window !== 'undefined') {
        // @ts-ignore
        urlPrefix = window.task_url;
      } else {
        // runs on server - config omitted from client side compile
        urlPrefix = require('config').get('server.tasks').get('url');
      }
      return urlPrefix + '/image/' + image.id + '/' + size + buster;
    }

    return scheme + '//' + image.bucket + '.s3.amazonaws.com/' + image.link_url + size + '/' + image.file_name + buster;
  }
}