'use strict';

require('core-js');

var jQuery = require('jquery'),
    $ = jQuery,
    _ = require('lodash'),
    Promise = require('bluebird'),
    Moment = require('lib/moment'),
    toastr = require('toastr');

window.jQuery = window.$ = $;

require('jquery-ui-bundle');
require('popper.js');
require('bootstrap');
require('intl-tel-input');
require('slick-carousel');

window._ = _;
window.toastr = toastr;
window.Moment = Moment;
window.CreditCards = require('lib/creditcards');
require('./utils');
require('lightbox2');

require('./helpers');

$(function() {
  $(document).on('click', '.disabled, [disabled]', function() {
    if ($(this).hasClass('dropzone'))
      return;
    return false;
  });
});

require('@snd/dropzone');

require('./crossbar');
require('./priorityOn');
require('./upperCase');
require('./forms');
require('./modals');
require('./select2');
require('./country_state');
require('./phones');
require('./datetimepicker');
require('./navigation');
require('./scrollToTop');
require('./tabs');
require('./vinexplosion');
require('./timer');
require('./card-collapse');
require('./readmore');