/* global $ */
'use strict';

var formSizes = {'form-control-sm': 'phn-flags-form-control-sm', 'form-control-lg': 'phn-flags-form-control-lg'};

$.fn.applyPhoneUI = function() {
  let self = this;
  
  this.intlTelInput({
    utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/12.1.13/js/utils.js',
    dropdownContainer: 'body'
  })
  .done(function() {
    self.each(function() {
      // initialize default values
      let $self = $(this),
          id = $self.attr('id');
      
      let $input = $('[name=' + id + ']');
      if (!$input.length) $input = $('#' + id + '_hidden');

      let $ext = $('#' + id + '_ext');
      let ext = $ext.val();

      let value = $input.val();
      if (value) {
        if (ext) value += ' ext. ' + ext;
        $self.intlTelInput('setNumber', value);
      }
    })
    .on('blur', function() {
      // input changed - update hidden values
      let $self = $(this),
          id = $self.attr('id'),
          noExt = $self.data('noext'),
          number = $self.intlTelInput('getNumber'),
          ext = $self.intlTelInput('getExtension');
      
      let $input = $('#' + id + '_hidden');
      $input.val(number);

      if (ext) {
        $input = $('[name=' + id + '_ext]');
        if ($input.length) $input = $('#' + id + '_ext');
        $input.val(ext);
        if (!noExt)
          number += ' ext. ' + ext;
      }
      $self.intlTelInput('setNumber', number);
    })
    .on('open:countrydropdown', function() {
      let $self = $(this);
      let $dropdown = $self.data('plugin_intlTelInput').dropdown;
      // debugger;
      for (var szClass in formSizes) {
        if ($self.hasClass(szClass)) {
          $dropdown.addClass(formSizes[szClass]);
        } else {
          $dropdown.removeClass(formSizes[szClass]);
        }
      }
    });
  })
  return this;
};

$(function() {

  $(document).on('shown.bs.modal', '.modal', function() {
    // set the focus
    $(this).find('.intl-phone').applyPhoneUI();
  });

  $('.intl-phone').applyPhoneUI();

});