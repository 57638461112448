/* global $ */
'use strict';

$('nav.site-menu li.submenu > a').click(function(e) {
  e.preventDefault();
  e.stopPropagation();

  let $parent = $(this).parent();
  $parent.siblings().removeClass('open');
  $parent.toggleClass('open');
});

$('.site-burger-menu button').click(e => {
  e.preventDefault();
  $('nav.site-menu > ul').toggleClass('open');
});

$(window).click(function() {
  $('nav.site-menu li.submenu.open').removeClass('open');
});

$('nav.site-menu li.submenu > ul').click(function(e) {
  e.stopPropagation();
});