/* global $ */
'use strict';

function performVinExplosion() {
  let $vin = $(this),
      $form = $vin.closest('form'),
      vin = $vin.val(),
      lastRan = $vin.data('vin-ran');

  if (!$form.length) return;
  let year = $form.find('input[data-vin-field=29]').val();

  if (vin === lastRan) return;

  $vin.data('vin-ran', vin);
  if (!vin || vin.length === 0) return;
  if (vin.length < 17) return $.handleFieldError('VIN must be 17 characters long', this);
  
  let updateVinField = false;
  if (vin.length > 17) {
    switch (vin.length) {
      case 18:
        vin = vin.substr(1); break;
      case 19:
        vin = vin.substr(1, 17); break;
      case 20:
        vin = vin.substr(2, 17); break;
    }
    if (vin.length === 17)
      updateVinField = vin;
  }

  if (updateVinField) {
    $vin.data('hold-title-gen', true)
      .val(updateVinField)
      .change()
      .data('hold-title-gen', false);
  }

  let url = 'https://vpic.nhtsa.dot.gov/api/vehicles/decodevin/' + vin + '?format=json';
  if (year) url += '&modelyear=' + year;
  $.getJSON(url)
  .done((data) => {
    if (!data || !data.Results || !data.Results.length)
      return $.handleFieldError('Unable to locate a vehicle with the specified VIN', $vin);
    
    let error = data.Results.find(r => r.VariableId === 143);
    if (!error) return $.handleFieldError('Invalid result data for VIN', $vin);
    if (error.ValueId !== '0') {
      var errorMsg = error.Value;
      // further details _may_ be found in 156
      return $.handleFieldError(errorMsg, $vin);
    }

    // _.each(data.Results, function(result) {
    //   $('input[data-vin-field=' + result.VariableId + ']').val(result.Value).trigger('input');
    // });
    for (let i = 0; i < data.Results.length; i++) {
      $('input[data-vin-field=' + data.Results[i].VariableId + ']')
        .data('hold-title-gen', true)
        .val(data.Results[i].Value)
        .trigger('input')
        .change()
        .data('hold-title-gen', false);
    }
    $vin.change();
  })
  .always(function() {
    $vin.data('vin-ran', vin);
  });
}

function clearVinError() {
  $(this).clearFieldError();
}

$.fn.enableVinExplosion = function(action) {
  this.off('blur', performVinExplosion)
      .off('input', clearVinError);

  if (action === 'destroy') return this;

  return this.blur(performVinExplosion)
    .on('input', clearVinError);
};