/* global $, _, Moment */

let timersToUpdate = [];
let timerStarted = false;

const timerFormats = {
};

let formatWarnings = {};

window.addEventListener('message', (e) => {
  if (e.data.msg_type !== 'timer') return;
  let $el = timersToUpdate[e.data.elIdx];
  let timer = $el.data('timer');
  if (!timer) {
    return;
  }

  if (!timer.is_closed) {
    let serverTime = Moment(e.data.serverTime);
    let durationCalc = Moment.duration(timer.end_time.diff(serverTime));
    let duration = durationCalc._data;
    duration.total = durationCalc._milliseconds;

    if (duration.years > 0 || duration.months > 0) {
      duration.days = Math.floor(durationCalc.as('days'));
    }
    delete duration.years;
    delete duration.months;

    let format = timer.format;
    if (format) {
      let typeofFormatFn = typeof timerFormats[format];
      if (typeofFormatFn !== 'function') {
        if (!formatWarnings[format]) {
          console.error(`Timer Format "${format}" is not a valid function (${typeofFormatFn}).`);
          formatWarnings[format] = true;
        }
        return;
      }
      timerFormats[format](timer, duration);
      return;
    }
    // if (!format) format = 'D [days] h:m:s [remaining]';

    let formattedDuration = '';
    if (duration.days > 0) {
      formattedDuration += `${duration.days} days `;
    }

    if (duration.hours > 0 || duration.days > 0) {
      formattedDuration += `${duration.hours}:`;
    }

    if (duration.minutes > 0 || duration.hours > 0 || duration.days > 0) {
      if (duration.days < 1 && duration.hours < 1) {
        formattedDuration += `${duration.minutes}:`;
      } else {
        formattedDuration += _.padStart(`${duration.minutes}`, 2, '0') + ':';
      }
    }

    if (duration.seconds > 0 || duration.minutes > 0 || duration.hours > 0 || duration.days > 0) {
      if (duration.minutes < 1 && duration.hours < 1 && duration.days < 1) {
        formattedDuration += `${duration.seconds} sec.`;
      } else {
        formattedDuration += _.padStart(`${duration.seconds}`, 2, '0');
      }
    }
    $el.text(formattedDuration);
  } else {
    // closed
  }
}, false);

function startTimer() {
  window.Crossbar.on('time', (e, serverTime) => {
    serverTime = Moment(serverTime).tz(window.webTimezone);
    for (let i = 0; i < timersToUpdate.length; i++) {
      window.postMessage({msg_type: 'timer', elIdx: i, serverTime: serverTime.format()}, '*');
    }
  });
  timerStarted = true;
}

window.initializeTimers = function initializeTimers() {
  $('.timer').each(function() {
    let $this = $(this);
    let timer = {
      end_time: Moment($this.data('end_time')),
      format: $this.data('format'),
    };

    $this.data('timer', timer);
    timersToUpdate.push($this);
  });

  if (!timerStarted) {
    startTimer();
  }
}

window.clearTimers = function clearTimers() {
  timersToUpdate = [];
}

$(function() {
  initializeTimers();
});