/* global $, Moment */
'use strict';

require('eonasdan-bootstrap-datetimepicker');

$.fn.dtp = function(options) {
  options = options || {};
  options = Object.assign(options, {
    icons: {
      time: 'far fa-clock',
      date: 'fal fa-calendar-alt',
      up: 'fas fa-chevron-up',
      down: 'fas fa-chevron-down',
      previous: 'fas fa-chevron-left',
      next: 'fas fa-chevron-right',
      today: 'far fa-crosshairs',
      clear: 'fal fa-trash-alt',
      close: 'fas fa-times'
    }
  });
  
  this.each((idx, element) => {
    let $element = $(element);
    let $target = $element;
    let $input = $element;
    let value = null;

    if ($element.prop('tagName') === 'INPUT' && $element.hasClass('form-control') && $element.parent().hasClass('input-group')) {
      $target = $element.parent();
      value = $input.val();
    }
    else if ($element.hasClass('input-group') && $element.find('input.form-control').length) {
      $input = $element.find('input.form-control');
      value = $input.val();
    }

    let dataFormat = $element.data('date-format');
    if (!options.format && dataFormat) options.format = dataFormat;

    delete options.defaultDate;
    if (value) {
      value = options.format ? Moment(value, options.format) : Moment(value);
      if (value.isValid())
        options.defaultDate = value;
    }

    let $modalBody = $target.closest('.modal-body');
    if ($modalBody.length) {
      options.widgetParent = 'body';
      $target.addClass('is-dtp-datepicker');
    }
    $target.addClass('date');
    if (!options.hasOwnProperty('sideBySide'))
      options.sideBySide = true;
    $target.datetimepicker(options);
  });
};

$(function() {
  $(document).on('shown.bs.modal', '.modal', function() {
    // set the focus
    $(this).find('.dtp-field').dtp();
  })
  .on('dp.show', '.modal-body .is-dtp-datepicker', function() {
    let $this = $(this);
    let $datepicker = $('body').find('.bootstrap-datetimepicker-widget:last');

    let top, left = $this.offset().left;
    if ($datepicker.hasClass('bottom')) {
      top = $this.offset().top + $this.outerHeight();
    }
    else if ($datepicker.hasClass('top')) {
      top = $this.offset().top - $datepicker.outerHeight();
    }

    $datepicker.css({
      'z-index': 2147483647,
      top: top + 'px',
      bottom: 'auto',
      left: left + 'px'
    });
  });

  // initialize stuff already on page
  $('.dtp-field').dtp();
});