
/* global $ */
'use strict';

$.fn.priorityOn = function(events, selector, data, handler) {
  if (!events) return this;
  var eventTypes = events.split(' ');
  if (!eventTypes || !eventTypes.length) return;

  this.each(function() {
    var $this = $(this);
    for (var i = 0; i < eventTypes.length; i++) {
      $this.on(eventTypes[i], selector, data, handler);

      var currentBindings = $._data(this, 'events')[eventTypes[i]];
      if ($.isArray(currentBindings))
        currentBindings.unshift(currentBindings.pop());
    }
  });
  return this;
};