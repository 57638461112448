module.exports={
  "_from": "cheerio@0.22.0",
  "_id": "cheerio@0.22.0",
  "_inBundle": false,
  "_integrity": "sha1-qbqoYKP5tZWmuBsahocxIe06Jp4=",
  "_location": "/cheerio",
  "_phantomChildren": {
    "inherits": "2.0.3",
    "string_decoder": "1.1.1",
    "util-deprecate": "1.0.2"
  },
  "_requested": {
    "type": "version",
    "registry": true,
    "raw": "cheerio@0.22.0",
    "name": "cheerio",
    "escapedName": "cheerio",
    "rawSpec": "0.22.0",
    "saveSpec": null,
    "fetchSpec": "0.22.0"
  },
  "_requiredBy": [
    "/truncate-html"
  ],
  "_resolved": "https://registry.npmjs.org/cheerio/-/cheerio-0.22.0.tgz",
  "_shasum": "a9baa860a3f9b595a6b81b1a86873121ed3a269e",
  "_spec": "cheerio@0.22.0",
  "_where": "/home/jma-front/source/node_modules/truncate-html",
  "author": {
    "name": "Matt Mueller",
    "email": "mattmuelle@gmail.com",
    "url": "mat.io"
  },
  "bugs": {
    "url": "https://github.com/cheeriojs/cheerio/issues"
  },
  "bundleDependencies": false,
  "dependencies": {
    "css-select": "~1.2.0",
    "dom-serializer": "~0.1.0",
    "entities": "~1.1.1",
    "htmlparser2": "^3.9.1",
    "lodash.assignin": "^4.0.9",
    "lodash.bind": "^4.1.4",
    "lodash.defaults": "^4.0.1",
    "lodash.filter": "^4.4.0",
    "lodash.flatten": "^4.2.0",
    "lodash.foreach": "^4.3.0",
    "lodash.map": "^4.4.0",
    "lodash.merge": "^4.4.0",
    "lodash.pick": "^4.2.1",
    "lodash.reduce": "^4.4.0",
    "lodash.reject": "^4.4.0",
    "lodash.some": "^4.4.0"
  },
  "deprecated": false,
  "description": "Tiny, fast, and elegant implementation of core jQuery designed specifically for the server",
  "devDependencies": {
    "benchmark": "^2.1.0",
    "coveralls": "^2.11.9",
    "expect.js": "~0.3.1",
    "istanbul": "^0.4.3",
    "jquery": "^3.0.0",
    "jsdom": "^9.2.1",
    "jshint": "^2.9.2",
    "mocha": "^2.5.3",
    "xyz": "~0.5.0"
  },
  "engines": {
    "node": ">= 0.6"
  },
  "files": [
    "index.js",
    "lib"
  ],
  "homepage": "https://github.com/cheeriojs/cheerio#readme",
  "keywords": [
    "htmlparser",
    "jquery",
    "selector",
    "scraper",
    "parser",
    "html"
  ],
  "license": "MIT",
  "main": "./index.js",
  "name": "cheerio",
  "repository": {
    "type": "git",
    "url": "git://github.com/cheeriojs/cheerio.git"
  },
  "scripts": {
    "test": "make test"
  },
  "version": "0.22.0"
}
