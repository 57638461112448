/* global $, toastr, util */
'use strict';

let openModals = [];

$.topModal = function() { return openModals[openModals.length - 1]; };

$.modal = function() {
  if (arguments.length === 0)
    return $($.topModal());
  
  // url, options
  let options = {};
  if (arguments.length === 1) {
    if (typeof arguments[0] === 'string')
      options.url = arguments[0];
    else if (typeof arguments[0] === 'object')
      options = arguments[0];
    else
      throw new Error('Invalid argument for modal');
  }

  if (arguments.length === 2) {
    if (typeof arguments[0] !== 'string')
      throw new Error('Expected URL string as first argument');
    if (arguments[1]) {
      if (typeof arguments[1] !== 'object')
        throw new Error('Invalid options argument');
      options = arguments[1];
    }
    options.url = arguments[0];
  }

  if (!options.id) options.id = 'modal_' + Date.now();
  
  let $M = $('[data-modal=remote-modal]#' + options.id);
  let createModal = false;
  if (!$M.length) {
    $M = $('<div id="' + options.id + '" tabindex="-1" role="dialog" data-modal="remote-modal"><div class="modal-dialog" role="document"><div class="modal-content" /></div></div>')
      .css('display', 'none')
      .appendTo('body');
    createModal = true;
  }

  let $dialog = $M.find('.modal-dialog');
  $dialog.removeClass('modal-fill modal-lg modal-sm modal-dialog-centered');
  if (options.size) $dialog.addClass(options.size);
  if (options.centered) $dialog.addClass('modal-dialog-centered');

  let getContent = Promise.resolve(false);
  if (options.html) getContent = Promise.resolve(options.html);
  else {
    let ajaxRequest = {method: 'GET', url: options.url};
    if (options.method) ajaxRequest.method = options.method;
    if (options.data) ajaxRequest.data = options.data;
    if (options.contentType) ajaxRequest.contentType = options.contentType;
    getContent = new Promise(resolve => {
      $.ajax(ajaxRequest)
        .done(html => resolve(html))
        .fail(result => {
          toastr.error(result.responseText);
          resolve(false);
        });
    })
  }

  let showOptions = {show: true};
  if (options.preventClose)
    showOptions = {backdrop: 'static', keyboard: false, show: true};

  return getContent.then(html => {
    if (!html) return html;
    return new Promise(resolve => {
      let classToAdd = 'modal fade';
      if (options.class) classToAdd += ' ' + options.class;

      $M.removeData('result')
        .removeClass()
        .addClass(classToAdd)
        .find('.modal-content').html(html).end()
        .one('hidden.bs.modal', function() {
          let result = $M.data('result');
          setTimeout(() => $M.remove(), 500);
          resolve(result);
        })
        .one('shown.bs.modal', () => {
          if (typeof options.shown === 'function')
            options.shown($M);
        })
        .removeClass('fade')
        .modal(showOptions);

      if (options.minHeight) {
        $M.find('.modal-body').css('min-height', options.minHeight);
      }
      
      if (!createModal) {
        $M.modal('handleUpdate');
      }
    });
  });
};

$.confirm = function(title, msg) {
  let button = null;
  if (typeof title === 'number') {
    [button, title, msg] = arguments;
  }
  
  if (typeof msg === 'undefined' || msg === null) {
    msg = title;
    title = undefined;
  }

  let $m = $('#confirm-modal');
  if (!$m.length) {
    $m = $('<div id="confirm-modal" class="modal fade" tabindex="-1" role="dialog"><div class="modal-dialog" role="document"><div class="modal-content"><div class="modal-body"></div><div class="modal-footer"><button type="button" class="btn btn-secondary" data-dismiss="modal">No</button><button type="button" class="btn btn-jma-gold" data-dismiss="modal">Yes</button></div></div></div></div>')
      .on('click', '.btn', function(e) { $m.data('result', $(e.target).is('.btn-jma-gold')); })
      .css('display', 'none')
      .appendTo('body');
  }

  return new Promise(resolve => {
    if (title) {
      $m.find('.modal-content').prepend($('<div class="modal-header"></div>').html(title));
    }
    $m.data('result', false)
      .find('.modal-body').html(msg).end()
      .one('hidden.bs.modal', () => {
        let result = $m.data('result');
        setTimeout(() => $m.remove(), 500);
        resolve(result);
      })
      .one('shown.bs.modal', () => {
        if (button === null) return;
        $m.find('.modal-footer button').eq(button).focus();
      })
      .modal({backdrop: 'static', keyboard: true, show: true})
  });
};

$(function() {
  
  $(document).on('shown.bs.modal', '.modal', function() {
    openModals.push(this);

    // set the focus
    var self = this;
    setTimeout(function() {
      let $input = $(self).find('[autofocus]').first();
      if (!$input.length) return;
      if ($input.hasClass('select2')) {
        $input.select2('focus');
        return;
      }
      $input.focus();
    }, 250);
  })
  .on('hidden.bs.modal', '.modal', function() {
    openModals.pop();
  });

  $(document).on('click', '.ajax-modal', function(e) {
    e.preventDefault();

    let options = {};
    let url = $(this).attr('href');
    let data = $(this).data();
    let dataKeys = Object.keys(data);
    for (let key of dataKeys) {
      if (!key.startsWith('modal') || key === 'modal') continue;
      options[key.substring(5).toLowerCase()] = data[key];
    }

    $.modal(url, options);
  });

  $(document).on('click', '.youtube-modal', function(e) {
    let embedUrl = $(this).attr('href');
    if (!embedUrl) return;

    e.preventDefault();

    let modalTitle = $(this).data('modal-title');
    let modal = `
    <div class="modal-header">
      <button class="close" type="button", data-dismiss="modal", aria-label="Close"><i class="far fa-times"/></button>
      <h3 class="modal-title">${modalTitle}</h3>
    </div>
    <div class="modal-body">
      <iframe style="height: 450px; width: 100%;" class="fill" src="${embedUrl}" frameborder="0" allowfullscreen></iframe>
    </div>
    `;

    $.modal({
      html: modal,
      size: 'modal-lg'
    });
  });

});