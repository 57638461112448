/* global $, _ */
'use strict';

require('select2')();

var templates = {
  credit_card: (option) => {
    if (!option.id || !option.element) return option.text;
    let icon = $(option.element).data('icon');
    if (!icon) return option.text;
    return $(`<span><i class="${icon}" /> ${option.text}</span>`);
  },
  categories: (option, el) => {
    let isResult = $(el).hasClass('select2-selection__rendered');
    if (!option.id || !option.element) return option.text;

    let $el = $(option.element);
    if (isResult) {
      return $el.data('path');
    }
    
    let indent = parseInt($el.data('indent'));
    return $('<span />').css('padding-left', (indent * 15) + 'px').text(option.text);
  }
};

$.setSelect2Template = function(template_name, fn) {
  if (!template_name)
    throw new Error('Missing template name');
  
  if (typeof fn === 'string') {
    if (!fn) throw new Error('Missing target template name');
    if (!templates.hasOwnProperty(fn))
      throw new Error(`Template function ${fn} does not exist`);
    templates[template_name] = templates[fn];
  }
  else if (typeof fn === 'function') {
    templates[template_name] = fn;
  }
  else {
    throw new Error(`'fn' parameter is not a template function (${typeof fn})`);
  }

  $('select.select2').each(function() {
    let $cbo = $(this);
    let select2 = $cbo.data('select2');
    if (select2) {
      if (select2.options.options.templateSelection_name === template_name)
        select2.options.options.templateSelection = templates[template_name];
      if (select2.options.options.templateResult_name === template_name)
        select2.options.options.templateResult = templates[template_name];
      $cbo.select2(select2.options.options);
    }
  });
};

$.fn.setSelect2Options = function(options) {
  this.each(function() {
    var select2 = $(this).data('select2');
    if (!select2) {
      $(this).data('select2-options', options);
    } else {
      _.assign(select2.options.options, options);
      $(this).select2(select2.options.options);
    }
  });
};

$.fn.destroySelect2 = function destroySelect2() {
  if (this.prop('tagName') === 'SELECT') {
    try {
      var sel2 = this.data('select2');
      if (sel2) {
        this.select2('destroy');
      }
    } catch(err) { }
    return this;
  } else {
    return this.find('.select2').each(function() {
      $(this).destroySelect2();
    });
  }
};

$.fn.enableSelect2 = function enableSelect2() {
  if (this.prop('tagName') !== 'SELECT') {
    return this.find('select.select2').each(function() {
      $(this).enableSelect2();
    });
  }

  // do NOT enable for select2 in templates
  if (this.closest('.template, template').length) return this;

  var self = this;
  var options = {dropdownAutoWidth: true};
  if (this.hasAttr('placeholder')) {
    options.placeholder = this.attr('placeholder');
    options.allowClear = true;
  }

  if (this.hasClass('no-clear')) {
    options.allowClear = false;
  }

  if (this.hasClass('no-search')) {
    options.minimumResultsForSearch = Infinity;
  }

  var modal = this.closest('.modal');
  if (modal.length) {
    options.dropdownParent = modal;
  }

  if (this.hasAttr('data-ajaxurl')) {
    options.templateSelection = function(data) {
      var e = $.Event('snd:formatSelection');
      e.params = data;
      self.trigger(e);
      if (e.isDefaultPrevented() || e.result)
        return e.result;
      return data.text;
    };

    options.templateResult = function(data) {
      var e = $.Event('snd:formatOption');
      e.params = data;
      self.trigger(e);
      if (e.isDefaultPrevented() || e.result)
        return e.result;
      return data.text;
    };

    options.ajax = {
      url: this.data('ajaxurl'),
      dataType: 'json',
      delay: 250,
      data: function(params) {
        var e = $.Event('snd:formatRequest');
        e.params = params;
        self.trigger(e);
        if (e.isDefaultPrevented() || e.result)
          return e.result;
      },
      processResults: function(data) {
        var e = $.Event('snd:formatResponse');
        e.params = data;
        self.trigger(e);
        if (e.isDefaultPrevented() || e.result)
          return { results: e.result };
        return { results: data };
      }
    };
  }

  if (this.hasAttr('data-tags')) {
    options.tags = true;
    options.createTag = function(params) {
      let e = $.Event('select2:createTag');
      e.params = params;
      self.trigger(e);
      if (e.isDefaultPrevented() || e.result)
        return e.result;

      let term = $.trim(params.term);
      if (term === '') return null;
      return {
        id: term,
        text: term,
        newTag: true
      };
    };
    if (this.hasAttr('data-tokens')) {
      options.tokenSeparators = this.data('tokens').split('');
    }
  }

  if (this.hasAttr('data-preserve-tag-order')) {
    this.on('select2:selecting', function(e) {
      // this - select box
      let data = $(this).data('ordered-data');
      if (!data) data = [];
      data.push(e.params.args.data._resultId);
      $(this).data('ordered-data', data);
    });

    this.on('select2:unselecting', function(e) {
      let data = $(this).data('ordered-data');
      if (!data) data = [];

      let idx = data.indexOf(e.params.args.data._resultId);
      if (idx >= 0) {
        data.splice(idx, 1);
      }
      $(this).data('ordered-data', data);
    });
  }

  // check for template for the dropdown items
  let tmpl = this.data('template');
  let selTemplate = this.data('option_template') || tmpl;
  let resTemplate = this.data('result_template') || tmpl;
  
  if (selTemplate) {
    if (!templates.hasOwnProperty(selTemplate))
      templates[selTemplate] = (option) => option.text;
    options.templateSelection = templates[selTemplate];
    options.templateSelection_name = selTemplate;
  }

  if (resTemplate) {
    if (!templates.hasOwnProperty(resTemplate))
      templates[resTemplate] = (option) => option.text;
    options.templateResult = templates[resTemplate];
    options.templateResult_name = resTemplate;
  }

  this.select2(options);
  let instance = this.data('select2');

  if (this.hasAttr('data-preserve-tag-order')) {
    instance.on('selection:update', function(e) {
      let data = this.$element.data('ordered-data');
      if (!data) data = [];

      let orderedData = data.map(id => e.data.find(d => d._resultId == id));
      this.selection.update(orderedData);
    });

    if (this.hasAttr('data-value')) {
      // hopefully is an array of just values...
      let dataVal = this.data('value');
      if (dataVal && Array.isArray(dataVal)) {
        setTimeout(function() {
          self.val(dataVal).change();
        }, 5);
      }
    }
  }

  if (this.hasClass('uppercase')) {
    if (this.hasAttr('multiple') && options.tags) {
      var $inputContainer = this.next();
      $inputContainer.on('focus', 'input.select2-search__field', function() {
        $(this).applyUpperCase();
      });
      $inputContainer.on('blur', 'input.select2-search__field', function() {
        $(this).applyUpperCase('destroy');
      });
    } else {
      this.on('select2:open', function() {
        var $input = $(this).data('select2').$dropdown.find('input.select2-search__field');
        $(this).data('select2').$dropdown.addClass('force-uppercase');
        $input.applyUpperCase();
        $input.data('select2', $(this).data('select2'));
      });
      this.on('select2:close', function() {
        var $input = $(this).data('select2').$dropdown.find('input.select2-search__field');
        $input.applyUpperCase('destroy');
      });
    }
  } else {
    this.on('select2:open', function() {
      var $input = $(this).data('select2').$dropdown.find('input.select2-search__field');
      $input.data('select2', $(this).data('select2'));
    });
  }
};

$(function() {
  var formSizes = {'form-control-sm': 'sel2-form-control-sm', 'form-control-lg': 'sel2-form-control-lg'};

  $(document).on('select2:open', '.select2-hidden-accessible', function() {
    var sel2 = $(this).data('select2');
    for (var szClass in formSizes) {
      if (sel2.$element.hasClass(szClass)) {
        sel2.$dropdown.addClass(formSizes[szClass]);
      } else {
        sel2.$dropdown.removeClass(formSizes[szClass]);
      }
    }

    var textTransform = sel2.$element.css('text-transform');
    sel2.$dropdown.removeClass('text-transform-none text-transform-uppercase text-transform-lowercase');
    sel2.$dropdown.addClass('text-transform-' + textTransform);
    if (sel2.$dropdown.$search)
      sel2.$dropdown.$search.data('select2', sel2);

    var zIndex = 2050 + (10 * $('.modal:visible').length);
    // setTimeout(function() { $('.select2-dropdown').css('z-index', zIndex + 1); }, 0);
    setTimeout(function() { sel2.$dropdown.css('z-index', zIndex + 1); }, 0);
  });

  $(document).on('keypress', '.select2-container--focus', function(e) {
    if ($(this).hasClass('select2-container--open')) return;
    let $element = $(this).data('element');
    if (!$element) {
      $element = $(this).prev('select.select2');
    }
    let select2 = $element.data('select2');
    let minResults = select2.options.options.minimumResultsForSearch;
    if (minResults === Infinity) return;

    let $search = select2.dropdown.$search;
    if (!$search) return;

    $element.select2('open');
    let searchVal = String.fromCharCode(e.which);
    if (!$element.hasClass('normalcase'))
      searchVal = searchVal.toLocaleUpperCase();
    $search.val(searchVal).change();
    select2.dropdown.trigger('query', {term: $search.val()});
  });

  $(document).on('keydown', 'input.select2-search__field', function(e) {
    if (e.which == 9) {
      let select2 = $(this).data('select2');
      let $results = select2.results.getHighlightedResults();
      if ($results.length < 1) {
        return;
      }

      let data = select2.dataAdapter.item($results);
      if ((data.element != null && data.element.selected) || (data.element == null && data.selected)) {
        // do not trigger select if already selected
        return;
      }
      select2.results.trigger('select', {data: data});
    }
  });

  $(document).on('select2:close', '.select2', function() {
    $(this).data('select2').$element.focus();
  })
  .on('shown.bs.modal', '.modal', function() {
    $(this).enableSelect2();
  });

  $('body').enableSelect2();
});