'use strict';

let Moment = require('moment-timezone');

Moment.createFake = function createFake() {
  return {
    isFake: true,
    format: function() { return ''; },
    isValid: function() { return false; },
    toJSON: function() { return ''; },
    toString: function() { return ''; }
  };
};

module.exports = Moment;