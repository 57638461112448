/* global $, _, toastr, util */
'use strict';

// .dP"Y8 88   88 88""Yb 8b    d8 88 888888     88  88    db    88b 88 8888b.  88     888888 88""Yb .dP"Y8 
// `Ybo." 88   88 88__dP 88b  d88 88   88       88  88   dPYb   88Yb88  8I  Yb 88     88__   88__dP `Ybo." 
// o.`Y8b Y8   8P 88""Yb 88YbdP88 88   88       888888  dP__Yb  88 Y88  8I  dY 88  .o 88""   88"Yb  o.`Y8b 
// 8bodP' `YbodP' 88oodP 88 YY 88 88   88       88  88 dP""""Yb 88  Y8 8888Y"  88ood8 888888 88  Yb 8bodP' 
let submitLock = false;
let submitAjax = [];
$(document).ajaxSend(function(e, jqXHR) {
  if (submitLock) submitAjax.push(jqXHR);
});

$.fn.submit = function(data, fn) {
  if (arguments.length < 1) {
    this.trigger('submit');
    return this;
  }

  if (typeof data === 'function') {
    var t = data;
    data = fn;
    fn = t;
  }
  
  var selected = this;
  var handler = function() {
    selected.clearErrors().find('.submit-button, button[type=submit], input[type=submit]').attr('disabled', 'disabled');

    // call the original handler
    submitLock = true;
    var result = fn.apply(this, arguments);
    $.when(result).then(function() {}, function() {}).then(function() {
      // we don't care what the result was, or whether it failed - just that it completed
      submitLock = false;
      var tasksRunning = [{}];
      while(submitAjax.length) tasksRunning.push(submitAjax.shift());
      $.when.apply($, tasksRunning).then(function(){}, function(){}).then(function() {
        // again we don't care the results - just that they are all finished
        selected.one('submit', null, data, handler);
        selected.find('.submit-button, button[type=submit], input[type=submit]').removeAttr('disabled');
      });
    });
    return result;
  };
  return this.one('submit', null, data, handler);
};

$.fn.submitLive = function(selector, fn) {
  if (arguments.length < 1) {
    this.trigger('submit');
    return this;
  }

  if (typeof fn !== 'function') {
    throw new Error('Expected handler function');
  }
  
  var selected = this;
  var handler = function(e) {
    var $form = $(this);
    $form.clearErrors().find('.submit-button, button[type=submit], input[type=submit]').attr('disabled', 'disabled');

    // call the original handler
    submitLock = true;
    var result = fn.apply(this, arguments);
    $.when(result).then(function() {}, function() {}).then(function() {
      // we don't care what the result was, or whether it failed - just that it completed
      submitLock = false;
      var tasksRunning = [{}];
      while(submitAjax.length) tasksRunning.push(submitAjax.shift());
      $.when.apply($, tasksRunning).then(function(){}, function(){}).then(function() {
        // again we don't care the results - just that they are all finished
        selected.one('submit', selector, handler);
        $form.find('.submit-button, button[type=submit], input[type=submit]').removeAttr('disabled');
      });
    });
    return result;
  };
  return this.one('submit', selector, handler);
};

$.fn.formsubmit = function(fn) { return this.on('snd:formsubmit', fn); };
$.fn.formerror = function(fn) { return this.on('snd:formerror', fn); };

//    db     88888    db    Yb  dP     8888b.     db    888888    db    
//   dPYb       88   dPYb    YbdP       8I  Yb   dPYb     88     dPYb   
//  dP__Yb  o.  88  dP__Yb   dPYb       8I  dY  dP__Yb    88    dP__Yb  
// dP""""Yb "bodP' dP""""Yb dP  Yb     8888Y"  dP""""Yb   88   dP""""Yb 
var rCRLF = /\r?\n/g,
  rsubmitterTypes = /^(?:submit|button|image|reset|file)$/i,
  rsubmittable = /^(?:input|select|textarea|keygen)/i;
    
function addDataToObject(obj, key, data) {
  if (Array.isArray(obj[key]))
    obj[key].push(data);
  else if (typeof obj[key] === 'undefined')
    obj[key] = data;
  else {
    obj[key] = [obj[key]];
    obj[key].push(data);
  }
}

$.fn.ajaxData = function(tinyMCE, useObjects) {
  if (document && document.activeElement && this[0]) {
    if (this[0].contains(document.activeElement)) {
      if (typeof document.activeElement.blur === 'function') {
        document.activeElement.blur();
      }
    }
  }
  var selfForm = this;
  var elements = this
    .map(function() {
      var elements = $.prop(this, "elements");
      return elements ? $.makeArray(elements) : this;
    })
    .filter(function() {
      var type = this.type;
      return this.name && !$(this).is(':disabled') && 
        rsubmittable.test(this.nodeName) && !rsubmitterTypes.test(type);
    });

  var data = {};
  elements.each(function(i, elem) {
    var val = $(elem).val();

    if (/^(?:checkbox)$/i.test(elem.type)) {
      if (!$(elem).hasClass('submit-value'))
        val = elem.checked ? 'true' : 'false';
      else if (!elem.checked)
        return;
    }

    if (/^(?:radio)$/i.test(elem.type) && !elem.checked)
      return;

    if (val == null) return;

    if (Array.isArray(val)) {
      $.each(val, function(i, val) {
        addDataToObject(data, elem.name, val.replace(rCRLF, "\r\n"));
      });
      return;
    }
    addDataToObject(data, elem.name, val.replace(rCRLF, "\r\n"));
  });

  if (tinyMCE && tinyMCE.editors) {
    _.each(tinyMCE.editors, function(e) {
      var frmElement = selfForm.find('#' + e.id);
      if (frmElement.length) {
        data[frmElement.attr('name')] = e.getContent();
      }
    });
  }

  var formattedData = {};
  _.forOwn(data, function(value, key) {
    if (useObjects) _.setWith(formattedData, key, value, Object);
    else _.set(formattedData, key, value);
  });

  this.trigger('snd:extraFormData', [formattedData]);
  return formattedData;
};

// 888888 88""Yb 88""Yb  dP"Yb  88""Yb     88  88    db    88b 88 8888b.  88     88 88b 88  dP""b8 
// 88__   88__dP 88__dP dP   Yb 88__dP     88  88   dPYb   88Yb88  8I  Yb 88     88 88Yb88 dP   `" 
// 88""   88"Yb  88"Yb  Yb   dP 88"Yb      888888  dP__Yb  88 Y88  8I  dY 88  .o 88 88 Y88 Yb  "88 
// 888888 88  Yb 88  Yb  YbodP  88  Yb     88  88 dP""""Yb 88  Y8 8888Y"  88ood8 88 88  Y8  YboodP 
$.fn.clearErrors = function() {
  this.removeClass('was-validated');
  this.find('.is-invalid').each(function() {
    $(this).removeClass('is-invalid').siblings('.invalid-tooltip').remove();
  });
  return this;
};

function isJson(data) {
  try {
    var json = JSON.parse(data); //eslint-disable-line no-unused-vars
    return true;
  } catch(e) {
    return false;
  }
}

/**
 * Displays the form messages
 * 
 * @param {any[]} messages 
 */
function displayFormMessages(messages) {
  if (!messages || !messages.length) return;
  for (let i = 0; i < messages.length; i++) {
    if (!messages[i].type)
      messages[i].type = 'success';
    toastr[messages[i].type](messages[i].message);
  }
}

function setFormData(selector, data) {
  if (!selector || typeof data !== 'object') return;
  let $form = $(selector);
  if (!$form.length) return;

  let formFields = Object.keys(data);
  if (formFields.length < 1) return;

  for (let i = 0; i < formFields.length; i++) {
    let $field = [];
    if (formFields[i][0] === '#') {
      $field = $(formFields[i]);
    } else {
      $field = $form.find('[name=' + $.safeAttr(formFields[i]) + ']');
    }
    if (!$field.length) {
      continue;
    }

    //  input|select|textarea
    let value = data[formFields[i]];

    if ($field[0].nodeName === 'SELECT') {
      $field.val(value);
      if ($field.hasClass('select2'))
        $field.trigger('change');
    }
    else if ($field[0].nodeName === 'TEXTAREA') {
      $field.val(value);
    }
    else if ($field[0].nodeName === 'INPUT') {
      let inputType = $field.attr('type');
      if (!inputType) inputType = 'text';
      inputType = inputType.toLowerCase();

      if (inputType === 'radio') {
        $field.filter('[value=' + $.safeAttr(value) + ']').prop('checked', true);
      }
      else if (inputType === 'checkbox') {
        $field.prop('checked', false);
        if (Array.isArray(value)) {
          for (let x = 0; x < value.length; x++) {
            $field.filter('[value=' + $.safeAttr(value[x]) + ']').prop('checked', true);
          }
        } else {
          $field.filter('[value=' + $.safeAttr(value) + ']').prop('checked', true);
        }
      }
      else {
        // textboxes and all other types
        $field.val(value);
      }
    }
  }
}

$.handleFormSuccess = function(selector, callback) {
  if (typeof selector === 'function') {
    callback = selector;
    selector = null;
  }

  if (typeof callback !== 'function') callback = null;

  return function(result, textStatus, jqXHR) {
    let formData = null;
    if (typeof result === 'object' && (result.action || result.actions)) {
      if (!Array.isArray(result.actions)) {
        result.actions = result.actions ? [result.actions] : [];
      }
      if (result.action) {
        result.actions.unshift(result.action);
      }

      let messages = [], redirect = null;
      for (let i = 0; i < result.actions.length; i++) {
        if (result.actions[i].message) {
          messages.push(result.actions[i]);
        }
        else if (result.actions[i].redirect) {
          redirect = result.actions[i].redirect;
        }
        else if (result.actions[i].form) {
          formData = result.actions[i].form;
        }
      }
      if (redirect) {
        // store any messages
        window.sessionStorage.setItem('snd:form-messages', JSON.stringify(messages));
        window.location = redirect;
        return;
      }
      if (formData) {
        setFormData(selector, formData);
      }
      displayFormMessages(messages);
    }
    if (callback) return callback.call($(selector), result, formData, textStatus, jqXHR);
  };
};

$.handleFormErrors = function(selector, callback) {
  if (typeof selector === 'function') {
    callback = selector;
    selector = null;
  }

  if (typeof callback !== 'function') callback = null;

  return function(result, status, xhr) {
    if (result.status === 0) {
      toastr.error("<strong>Connection Issue</strong>: There is an issue connecting to the server right now");
    } else {
      if (isJson(result.responseText)) {
        if (JSON.parse(result.responseText).errors != null) {
          result.response = JSON.parse(result.responseText);
          if (result.response.errors) {
            _.each(result.response.errors, function(err){
              var message = err.message;
              if (err.extra) {
                if (err.extra.redirect) message = message += '<br><a href=' + err.extra.redirect.link + '><strong>' + err.extra.redirect.label + '</strong></a>';
                if (!err.extra.no_toast)
                  toastr.error(message);
              } else {
                toastr.error(message);
              }
              handleFieldError(err, selector);
            });
          }
          if (result.response.message) {
            toastr.error(result.response.message);
          }
        }
      } else {
        toastr.error(result.responseText);
      }
    }
    if (callback) return callback.call($(selector), result, status, xhr);
  };
};

function handleFieldError(err, selector) {
  if (typeof err === 'string') {
    err = {
      param: (typeof selector === 'object' ? '#'+$(selector).attr('id') : 'snd-bogus-selector'),
      message: err
    };
    selector = null;
  }
  
  var fldSelector = /^[\#\. \[\]]/.test(err.param) ? err.param : '[name="' + err.param + '"]';
  var fld = selector ? $(selector).find(fldSelector) : $(fldSelector);

  if (!fld.length && !/^[\#\. \[\]]/.test(err.param) && /\[\d+\]$/.test(err.param)) {
    fldSelector = '[name="' + err.param.replace(/\[(\d+?)\]$/, '.\$1') + '"]';
    fld = selector ? $(selector).find(fldSelector) : $(fldSelector);
  }

  if (fld.length && fld.attr('type') === 'hidden') {
    let telInput = fld.parent().find('.input-group .intl-tel-input input.form-control');
    if (telInput.length) {
      fld = telInput;
    }
  }
  
  if (fld.length) {
    if (fld.prop('tagName') === 'SELECT' && fld.hasClass('select2')) {
      fld.addClass('is-invalid')
        .next('.select2-container')
        .after($('<div class="invalid-tooltip"></div>').text(err.message));
    } else if (fld.data('phone-input-id')) {
      fld = $('#' + fld.data('phone-input-id'));
      fld.addClass('is-invalid')
        .after($('<div class="invalid-tooltip"></div>').text(err.message));
    } else {
      fld.addClass('is-invalid')
        // .parent()
        .after($('<div class="invalid-tooltip"></div>').text(err.message));
    }
  }
  
  if (!fld.length && selector) {
    $(selector).trigger('snd:extraFormErrors', [err]);
  }
}
$.handleFieldError = function(err, selector) {
  return handleFieldError(err, selector);
};

$.fn.clearFieldError = function() {
  this.removeClass('is-invalid')
    .siblings('.invalid-tooltip').remove();
  return this;
};

function errorTooltipPosition(e) {
  var tooltipID = $(e.currentTarget).attr('aria-describedby');
  var $tooltip = $('#' + tooltipID);
  if (!$tooltip.length) return;

  var actualWidth = $tooltip[0].offsetWidth;
  var newLeft = e.pageX - (actualWidth / 2);
  newLeft = Math.round(newLeft);
  $tooltip.css({
    left: newLeft + 'px',
    'z-index': 999999
  });
}

$.hiddenSubmit = function(frm, data) {
  var form = document.createElement('form');
  form.action = frm.url;
  form.method = frm.method || 'POST';
  form.target = frm.target || '_self';
  if (data) {
    for (var key in data) {
      if (!data.hasOwnProperty(key)) continue;
      var input = document.createElement('textarea');
      input.name = key;
      input.value = (typeof data[key] === 'object') ? ':json:' + JSON.stringify(data[key]) : data[key];
      form.appendChild(input);
    }
  }
  form.style.display = 'none';
  document.body.appendChild(form);
  form.submit();
};

// 88b 88 88   88 8b    d8 888888 88""Yb 88  dP""b8      dP"Yb  88b 88 88     Yb  dP 
// 88Yb88 88   88 88b  d88 88__   88__dP 88 dP   `"     dP   Yb 88Yb88 88      YbdP  
// 88 Y88 Y8   8P 88YbdP88 88""   88"Yb  88 Yb          Yb   dP 88 Y88 88  .o   8P   
// 88  Y8 `YbodP' 88 YY 88 888888 88  Yb 88  YboodP      YbodP  88  Y8 88ood8  dP    
$.fn.numericOnly = function(selector) {
  if (selector) {
    this.on('keydown', selector, numericOnlyHandler);
  } else {
    this.on('keydown', numericOnlyHandler);
  }
};

var numericKeyCodes = _.concat(_.range(48, 58), _.range(96, 106), 8, 9, 13, 37, 39, 46, 110, 190);
function numericOnlyHandler(e) {
  if (e.shiftKey) e.preventDefault();
  if (!_.includes(numericKeyCodes, e.keyCode)) e.preventDefault();
  if ($(e.currentTarget).val().indexOf('.') !== -1 && (e.keyCode === 190 || e.keyCode === 110))
    e.preventDefault();
}

// Polyfill html5 form attr
let html5FormAttrSupported = null;
function supportsFormAttr() {
  if (html5FormAttrSupported !== null)
    return html5FormAttrSupported;
  
  let id = '_tmp' + Date.now();
  let div = document.createElement('div');
  div.style.display = 'none';

  let form = document.createElement('form');
  form.id = id;

  let input = document.createElement('input');
  input.setAttribute('form', id);

  document.body.appendChild(div);
  div.appendChild(form);
  div.appendChild(input);
  html5FormAttrSupported = (input.form === form);
  document.body.removeChild(div);

  return html5FormAttrSupported;
}

// 8888b.   dP"Yb   dP""b8 88   88 8b    d8 888888 88b 88 888888     888888 Yb    dP 888888 88b 88 888888 .dP"Y8 
//  8I  Yb dP   Yb dP   `" 88   88 88b  d88 88__   88Yb88   88       88__    Yb  dP  88__   88Yb88   88   `Ybo." 
//  8I  dY Yb   dP Yb      Y8   8P 88YbdP88 88""   88 Y88   88       88""     YbdP   88""   88 Y88   88   o.`Y8b 
// 8888Y"   YbodP   YboodP `YbodP' 88 YY 88 888888 88  Y8   88       888888    YP    888888 88  Y8   88   8bodP' 
$(function() {
  let formMessages = window.sessionStorage.getItem('snd:form-messages');
  if (formMessages) {
    formMessages = JSON.parse(formMessages);
    displayFormMessages(formMessages);
    window.sessionStorage.removeItem('snd:form-messages');
  }

  $(document).submitLive('form:not(.no-ajax)', function(e) {
    e.preventDefault();
    
    let $form = $(e.target);
    let useObjects = false;
    if (this.hasAttribute('data-ajax-objects')) { //1FMCU9HD8JUC79958
      useObjects = true;
    }
    let data = $(this).ajaxData(null, useObjects);

    let evt = $.Event('snd:beforesubmit');
    $form.trigger(evt, data);
    if (evt.isDefaultPrevented())
      return;

    // let returnedMethod = evt.result ? evt.result.method : undefined;
    // let url = evt.result
    let mthd, url;
    if (evt.result) {
      mthd = evt.result.method;
      url = evt.result.url;
    }

    mthd = mthd || $form.attr('method') || 'POST';
    url = url || $form.attr('action') || util.buildHref();

    let doStringify = true;
    if (typeof data === 'object' && mthd.toLowerCase() === 'get') {
      let propNames = Object.getOwnPropertyNames(data);
      if (!propNames.length)
        data = undefined;
      else
        doStringify = false;
    }
    $.ajax({
      method: mthd,
      url: url,
      contentType: 'application/json',
      data: doStringify ? JSON.stringify(data) : data
    })
    .done($.handleFormSuccess($form, function(result, resultFormData) {
      $form.trigger('snd:formsubmit', [result, data, resultFormData]);
    }))
    .fail($.handleFormErrors($form, function(result) {
      $form.trigger('snd:formerror', [result, data]);
    }));
  });

  // $('body').tooltip({
  //   selector: '.has-error, .hasError',
  //   container: 'body',
  //   placement: 'auto top',
  //   trigger: 'hover',
  //   title: function() { return $(this).data('err-msg'); },
  //   template: '<div class="tooltip has-error" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner"></div></div>'
  // });

  $(document).on('click', '.input-group-append .showPassword', function() {
    let $btn = $(this);
    let $input = $btn.closest('.input-group').find('input');
    if ($btn.hasClass('active')) {
      // turn it off
      $input.attr('type', 'password');
      $btn.removeClass('active').attr('aria-pressed', 'false');
    } else {
      // turn it on
      $input.attr('type', 'text');
      $btn.addClass('active').attr('aria-pressed', 'true');
    }
    $btn.find('i.far').toggleClass('fa-eye fa-eye-slash');
  });

  if (!supportsFormAttr()) {
    $(document).on('click', 'button[form], input[form]', function(e) {
      if (e.isDefaultPrevented()) {
        return;
      }

      // is submit button?
      let btnType = $(this).attr('type');
      if (btnType !== 'submit' && btnType !== 'image') {
        // not a submit button
        return;
      }
      
      let formId = $(this).attr('form');
      if (formId) {
        let $form = $(this).closest('form');
        if (!$form.length || $form.attr('id') !== formId) {
          $form = $('form#' + formId);
          if ($form.length === 1) {
            $form.submit();
          } else {
            if ($form.length) {
              console.error(`Multiple forms detected - unable to submit (${$form.length})`);
            }
            e.preventDefault();
          }
        }
      }
    });
  }
});