/* global $ */
'use strict';

$(window).scroll(function() {
  if ($(this).scrollTop() > 100) {
    $('#scrollToTop').fadeIn();
  } else {
    $('#scrollToTop').fadeOut();
  }
});

$('#scrollToTop').click(e => {
  e.preventDefault();
  $('html, body').animate({scrollTop: 0}, 800);
});