module.exports={
  "_from": "autobahn@^0.9.9",
  "_id": "autobahn@0.9.9",
  "_inBundle": false,
  "_integrity": "sha1-6Z7x6pssmKVcRPsZu0XOvB9SGQc=",
  "_location": "/autobahn",
  "_phantomChildren": {},
  "_requested": {
    "type": "range",
    "registry": true,
    "raw": "autobahn@^0.9.9",
    "name": "autobahn",
    "escapedName": "autobahn",
    "rawSpec": "^0.9.9",
    "saveSpec": null,
    "fetchSpec": "^0.9.9"
  },
  "_requiredBy": [
    "/"
  ],
  "_resolved": "https://registry.npmjs.org/autobahn/-/autobahn-0.9.9.tgz",
  "_shasum": "e99ef1ea9b2c98a55c44fb19bb45cebc1f521907",
  "_spec": "autobahn@^0.9.9",
  "_where": "/home/jma-front/source",
  "author": {
    "name": "Tavendo GmbH"
  },
  "browser": {
    "lib/transport/rawsocket.js": false
  },
  "bugs": {
    "url": "https://github.com/crossbario/autobahn-js/issues"
  },
  "bundleDependencies": false,
  "dependencies": {
    "crypto-js": ">= 3.1.5",
    "when": ">= 3.7.3",
    "ws": ">= 0.8.0"
  },
  "deprecated": false,
  "description": "An implementation of The Web Application Messaging Protocol (WAMP).",
  "devDependencies": {
    "browserify": ">= 11.0.1",
    "nodeunit": ">= 0.9.1"
  },
  "homepage": "https://github.com/crossbario/autobahn-js#readme",
  "keywords": [
    "WAMP",
    "WebSocket",
    "RPC",
    "PubSub"
  ],
  "license": "MIT",
  "main": "index.js",
  "name": "autobahn",
  "repository": {
    "type": "git",
    "url": "git://github.com/crossbario/autobahn-js.git"
  },
  "scripts": {
    "test": "nodeunit test/test.js"
  },
  "version": "0.9.9"
}
